import React from "react"
import { Link } from "gatsby"
import BlogStyles from "./header.module.scss"
import headerStyles from "./header.module.scss"

const HeaderMercearia = () => {
  return(
    <nav>
    <ul className={BlogStyles.navList}>
      <li>
        <Link
            className={headerStyles.navItem}
            activeClassName={headerStyles.activeNavItem}
          to="/conservas"
        >
          Pickled Food
        </Link>
      </li> 
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/docesbisc"
        >
          Sweets|Cookies|Snacks
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/farinhagraos"
        >
          Flour|Grains|Ferment
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/leitepoachoc"
        >
          Milk and Chocolate Powder
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/massasmolhos"
        >
          Pasta and Sauces
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/oleos"
        >
          Oils
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/leite"
        >
          Milk
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/temperos"
        >
          Spices
        </Link>
      </li>
      <li>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/cafe"
        >
          Coffee
        </Link>
      </li>
    </ul>
  </nav>
  )
}
export default HeaderMercearia